// extracted by mini-css-extract-plugin
export var root = "projectCard-module--root--bjY-Y";
export var innerCard = "projectCard-module--innerCard--sL7CK";
export var innerCardVideo = "projectCard-module--innerCardVideo--IYve-";
export var innerCardMoreInfo = "projectCard-module--innerCardMoreInfo--Q8sPe";
export var actions = "projectCard-module--actions--y11Yk";
export var actionIcon = "projectCard-module--actionIcon--yN3Vh";
export var innerText = "projectCard-module--innerText--1qhDE";
export var cardTitle = "projectCard-module--cardTitle--2Kwo0";
export var cardTitleSecondary = "projectCard-module--cardTitleSecondary--RgKpX";
export var cardDescription = "projectCard-module--cardDescription--5l2jq";
export var cardLogo = "projectCard-module--cardLogo--vS+Rl";
export var tooltipStyles = "projectCard-module--tooltipStyles--PYpXR";
export var imageContainer = "projectCard-module--imageContainer--XMN8B";
export var flipAnimation = "projectCard-module--flipAnimation--0yP0-";
export var flip = "projectCard-module--flip--nydPK";
export var flipAnimationBack = "projectCard-module--flipAnimationBack--SpDJD";
export var flipBack = "projectCard-module--flipBack--A120i";